import "./register.css";
import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous error
    setError("");

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/user/register`, {
        username,
        email,
        password,
        referrer: referralCode,
      });
      alert("User registered successfully");
      history.push("/login");
    } catch (error) {
      // Provide a more detailed error message
      setError(
        error.response?.data?.message ||
          "Registration failed. Please try again."
      );
    }
  };

  return (
    <>
      <div className="info-container">
        <h2 style={{ marginLeft: "8rem" }}>Join Us!</h2>
        <p style={{ color: "white", textAlign: "center", marginLeft: "8rem" }}>
          Create an account to start your journey with us
        </p>
      </div>
      <div className="card">
        <div className="card-column">
          <h1>Register</h1>
          <h4>Create your account.</h4>
        </div>
        <div className="card-column">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <input
              type="text"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              placeholder="Referral Code"
            />
            <button type="submit">Register</button>
          </form>
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
      <p style={{ textAlign: "center", color: "white", marginLeft: "7rem" }}>
        Already have an account?
      </p>
      <p style={{ textAlign: "center", color: "white", marginLeft: "7rem" }}>
        Login{" "}
        <a href="/login" style={{ color: "skyblue", fontWeight: "bold" }}>
          Here
        </a>
      </p>
    </>
  );
};

export default Register;
