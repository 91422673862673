import React, { useState } from "react";
import "./ShareIssue.css";
import Logo from "../images/u4us/logo.png";
import { useHistory } from 'react-router-dom';
import { Modal } from "antd";

const ShareIssue = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const gotoMessage = () => {
    window.open('/register', '_blank', 'noopener,noreferrer');
  };


  return (
    <div className="main">
      <div className="logo" style={{ marginBottom: "10px" }}>
        <img className="logo-image" src={Logo} alt="logo" width={440} height={120}></img>
      </div>
      <h1
        style={{ textAlign: "center", marginBottom: "20px" }}
        className="font-fam text-h1"
      >
        Multi-Issue Coalition <br />
        United by a Common Goal
      </h1>

      <h1 style={{ marginBottom: "18px !important", textAlign: "center" }} className="text-h1 font-fam">SEND YOUR MESSAGE</h1>
      <h2 className="text-h2 font-fam">
        Your Followers and Theirs Will Take it Viral <br /> Track Your Exponential Influence <br />
        Receive Donated Rewards
      </h2>
      <div style={{ display: "flex" }}>
        <button onClick={gotoMessage}
          className="font-fam custom-button"
          style={{
            margin: "0 auto",
            backgroundColor: "#90ed49",
            color: "#4802e2",
            fontWeight: "700",
            marginBottom: "29px",
            marginTop: "5px",
            width: "170px"

          }}
        >
          IT'S FREE & EASY
        </button>

      </div>

      <div>
        <h3 style={{ textAlign: "center", marginBottom: "0px" }} className="text-h3 font-fam italic fw-400">And if you can</h3>
        <h3
          onClick={showModal}
          style={{ textAlign: 'center', marginTop: "0px", marginBottom: "0px", cursor: "pointer", textDecoration: "underline" }}
          className="text-h2  font-fam">
          DONATE
        </h3>
        <Modal
          styles=
          {{
            content: { backgroundColor: '#0000ff', color: 'white !important', fontFamily: "Poppins", fontWeight: "500" },
          }}
          open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
          <h2 className="text-h2 font-fam">Donate Directly</h2>
          <p style={{ textAlign: "center", marginTop: "0" }} className="italic font-fam">click on one each time</p>
          <div>
            <div className="main-container">

              <a style={{ textAlign: 'center', marginBottom: "5px" }} className="text-h3  no-underline" target="blank" href="https://secure.actblue.com/donate/ads-gs-dd-kdhtjwsearch-august2024?refcode=om2024_ads_gs_240721_hvfphrase_dd_us_all_campaign_vpannouncement&refcode2=vpannouncement&gad_source=1&gclid=EAIaIQobChMIhPmu7uPjhwMVngetBh3PXDCQEAAYASABEgKH6PD_BwE">
                HARRIS WALTZ CAMPAIGN
              </a>

              <a style={{ textAlign: 'center', marginBottom: "5px" }} className="text-h3  no-underline" target="blank" href="https://www.weareplannedparenthoodaction.org/onlineactions/6iOI0_HnUUmPu_6_SRgayg2?sourceid=1017126&ms=4NALzzz00z1N1A&utm_campaign=4NIAU24XA_0000000107_0E1NAC&utm_source=Google&utm_medium=cpm&gclid=EAIaIQobChMI5_2t8_TMhwMVSEZ_AB0mXyMbEAAYASAAEgKYHPD_BwE&gclsrc=aw.ds">
                REPRODUCTIVE FREEDOM
              </a>
            </div>
            <div className="main-container">
              <a style={{ textAlign: 'center', marginBottom: "5px" }} className=" text-h3  no-underline" target="blank" href="https://action.aarp.org/ADV_Donation_Support">
                MEDICAL & SOCIAL SECURITY
              </a>

              <a style={{ textAlign: 'center', marginBottom: "5px" }} className=" text-h3 no-underline" target="blank" href="https://ctj.org/donate/">
                FAIR SHARE TAXATION
              </a>

            </div>
            <div className="main-container">
              <a style={{ textAlign: 'center', marginBottom: "5px" }} className=" text-h3  no-underline" target="blank" href="https://act.sierraclub.org/donate/rc_connect__campaign_designform?id=7013q000002IMZKAA4&formcampaignid=7013q000002IK4lAAG&ddi=N24ZSEGO02&gclid=EAIaIQobChMInbf5lPnMhwMVh3J_AB0higDIEAAYASAAEgIhMvD_BwE&gclsrc=aw.ds">
                CLIMATE CONTROL
              </a>

              <a style={{ textAlign: 'center', marginBottom: "5px" }} className="text-h3  no-underline" target="blank" href="https://petitions.signforgood.com/et-2023-end-gun-violence-interstitial-v2?refcode=ggnp_everg-d2d-0324-branded-interstitial&gclid=EAIaIQobChMItfCcnvvMhwMVHCzUAR2Y0DtDEAAYASAAEgIZxfD_BwE">
                GUN REGULATION
              </a>
            </div>
            <div className="main-container">
              <a style={{ textAlign: 'center', marginBottom: "5px" }} className="text-h3 no-underline" target="blank" href="https://action.aclu.org/give/now">
                CIVIL RIGHTS
              </a>

              <a style={{ textAlign: 'center', marginBottom: "5px" }} className="text-h3 no-underline" target="blank" href="https://engage.welcome.us/a/donate?_gl=1&gad_source=1&gclid=EAIaIQobChMI6LLVsv7MhwMVISCtBh04MDWFEAAYASAAEgJ2__D_BwE">
                HUMANE IMMIGTATION
              </a>

            </div>
          </div>
        </Modal>
        <h3 style={{ textAlign: 'center', marginTop: "0px", marginBottom: "19px !important" }} className="text-h3 font-fam italic fw-400">Direct To One Or More Causes</h3>

        <h3 style={{ textAlign: "center", marginBottom: "0px" }} className="text-h2 italic font-fam">VIRAL MESSAGING by EXPONICS</h3>
        <h3 style={{ textAlign: "center", marginBottom: "0px" }} className="text-h3  fw-400 font-fam">© 2024 U4US.org All Rights Reserved</h3>

      </div>



    </div>

  );
};

export default ShareIssue;
